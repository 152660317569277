import React from 'react';
import { Popup } from 'react-mapbox-gl';
import Icon from '../../assets/svg/Icon';

class MapPopup extends React.Component {

  render() {
    const { location, renderPopup } = this.props;

    const imgUrl = `/images/icons/multi/${location.icon_key}.png`;

    const createMarkup = html => { return { __html: html }; };

    return <Popup
      className={'map-popup-container'}
      coordinates={[location.longitude, location.latitude]}
      offset={{
        'bottom-left': [12, -38], 'bottom': [0, -38], 'bottom-right': [-12, -38]
    }}>
      <div className={'close-marker'} onClick={() => renderPopup()}>
        <img src={'/images/icons/close.png'} alt="Close" title="Close" />
      </div>
      <div className={'marker-header'}>
        <p className={'location-title'} dangerouslySetInnerHTML={createMarkup(location.title)} />
        <p className={'location-icon'}>
          <img src={imgUrl} alt={location.icon_title} title={location.icon_title} />
        </p>
      </div>
      <div className={'marker-container'}>
        <div className={'marker-content-left'}>
          <p className={'marker-location'}><Icon /> <span dangerouslySetInnerHTML={createMarkup(location.location)} /></p>
          {location.hours !== '' && <p dangerouslySetInnerHTML={createMarkup(location.hours)}/>}
          {location.telephone !== '' && <p dangerouslySetInnerHTML={createMarkup(`<a href="tel:${location.telephone}">${location.telephone}</a>`)}/>}
          {location.website !== '' && <p dangerouslySetInnerHTML={createMarkup(`<a href="${location.website}" target="_blank" rel="nofollow">${location.website}</a>`)}/>}
        </div>
        <div className={'marker-content-right'}>
          {location.image !== '' && <p className={'marker-image'}><img src={`https://portlandjetport.org${location.image}`} alt='' title='' /></p>}
          {location.copy !== '' && <div className={'copy-container'} dangerouslySetInnerHTML={createMarkup(location.copy)}/>}
        </div>
      </div>
    </Popup>
  }
}

export default MapPopup;
