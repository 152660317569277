import React from 'react';

const LoadingSVG = ({
  classname = "LoadingSvgImage",
  viewBox = "0 0 100 100",
  height = "200px",
  width = "200px",
}) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox}
      preserveAspectRatio="xMidYMid"
      className={classname}
    >
      <circle cx="50" cy="50" r="22" strokeWidth="4" stroke="#0054ac" strokeDasharray="34.55751918948772 34.55751918948772" fill="none" strokeLinecap="round" transform="rotate(9.43281 50 50)">
        <animateTransform attributeName="transform" type="rotate" dur="2.7777777777777777s" repeatCount="indefinite" keyTimes="0;1" values="0 50 50;360 50 50"></animateTransform>
      </circle>
      <circle cx="50" cy="50" r="17" strokeWidth="4" stroke="#b0b0b0" strokeDasharray="26.703537555513243 26.703537555513243" strokeDashoffset="26.703537555513243" fill="none" strokeLinecap="round" transform="rotate(-9.43281 50 50)">
        <animateTransform attributeName="transform" type="rotate" dur="2.7777777777777777s" repeatCount="indefinite" keyTimes="0;1" values="0 50 50;-360 50 50"></animateTransform>
      </circle>
    </svg>
  );

export default LoadingSVG;
