import {
  UPDATE_POPUP,
  UPDATE_SEARCH,
  UPDATE_SEARCH_TYPING,
  UPDATE_SEARCH_EMPTY_FLAG,
  UPDATE_CATEGORY,
  UPDATE_CATEGORIES,
  UPDATE_LEVEL,
  UPDATE_LEVELS,
  UPDATE_MAP_BOUNDS,
  UPDATE_LOCATIONS_LOADED,
  UPDATE_LOCATIONS,
  UPDATE_ICONS,
} from '../actions/Map';

const initialState = {
  pid: '',
  searchText: '',
  searchTyping: false,
  searchEmptyFlag: false,
  category: '',
  categories: null,
  level: '',
  levels: null,
  bounds: [['-70.315226', '43.650194'], ['-70.306815', '43.646739']],
  defaultBounds: null,
  locationsLoaded: false,
  locations: null,
  icons: null,
};

export default function App(state = initialState, action) {

  switch (action.type) {

    case UPDATE_POPUP:
      return Object.assign({}, state, {
        pid: action.payload.pid,
      });

    case UPDATE_SEARCH:
      return Object.assign({}, state, {
        searchText: action.payload.searchText,
      });

    case UPDATE_SEARCH_TYPING:
      return Object.assign({}, state, {
        searchTyping: action.payload.what,
      });

    case UPDATE_SEARCH_EMPTY_FLAG:
      return Object.assign({}, state, {
        searchEmptyFlag: action.payload.what,
      });

    case UPDATE_CATEGORY:
      return Object.assign({}, state, {
        category: action.payload.category,
      });

    case UPDATE_CATEGORIES:
      return Object.assign({}, state, {
        categories: action.payload.categories,
      });

    case UPDATE_LEVEL:
      return Object.assign({}, state, {
        level: action.payload.level,
      });

    case UPDATE_LEVELS:
      return Object.assign({}, state, {
        levels: action.payload.levels,
      });

    case UPDATE_MAP_BOUNDS:
      const defaultBounds = (state.defaultBounds === null) ? action.payload.bounds : state.defaultBounds;
      return Object.assign({}, state, {
        bounds: action.payload.bounds,
        defaultBounds: defaultBounds,
      });

    case UPDATE_LOCATIONS_LOADED:
      return Object.assign({}, state, {
        locationsLoaded: true
      });

    case UPDATE_LOCATIONS:
      return Object.assign({}, state, {
        locations: action.payload.locations
      });

    case UPDATE_ICONS:
      return Object.assign({}, state, {
        icons: action.payload.icons
      });

    default:
      return state;
  }
};
