import React from 'react';

// React Redux
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

// Reducers
import reducer from './reducers';

import MapComponent from './components/Map';

const Store = createStore(
  reducer,
  applyMiddleware(thunk),
);


function App() {
  return (
    <Provider store={Store}>
      <MapComponent />
    </Provider>
  );
}

export default App;
