import React from 'react';
import { connect } from 'react-redux';
import MapLayer from './MapLayer';

import {
  dispatchUpdateSearchEmptyFlag,
  getCategoryObject,
  getLevelObject,
  getIconObj,
  dispatchUpdateMapBounds,
} from '../../actions/Map';

class Locations extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {

    return (
      !nextProps.searchTyping &&
      (
        this.props.searchTyping !== nextProps.searchTyping ||
        this.props.searchText !== nextProps.searchText ||
        this.props.category !== nextProps.category ||
        this.props.level !== nextProps.level ||
        this.props.locations !== nextProps.locations ||
        this.props.icons !== nextProps.icons ||
        this.state !== nextState
      )
    ) ? true : false;
  }

  getLocations = () => {
    const { locations, searchText, category, categories, level, levels, getCategoryObject, getLevelObject, dispatchUpdateMapBounds, dispatchUpdateSearchEmptyFlag } = this.props;

    let tmpLocations = [];
    let counter = 0;

    const searchTerm = searchText.toLowerCase();
    const filterCategory = getCategoryObject(category, categories);
    const filterLevel = getLevelObject(level, levels);

    for (let i in locations) {
      let location = locations[i];

      const title = location.title.toLowerCase();
      const copy = location.copy.toLowerCase();
      const locCategory = location.category.toLowerCase();
      const locLevel = location.level.toLowerCase();
      const icon = location.icon_title.toLowerCase();
      const locationSearchTerms = location.search_terms.toLowerCase();

      location.display = (
        (searchTerm === '' || title.includes(searchTerm) || copy.includes(searchTerm) || icon.includes(searchTerm) || locationSearchTerms.includes(searchTerm)) &&
        (filterCategory === false || (filterCategory !== false && filterCategory.title.toLowerCase() === locCategory)) &&
        (filterLevel === false || (filterLevel !== false && filterLevel.title.toLowerCase() === locLevel))
      ) ? true : false;

      if (location.display) counter++;
      tmpLocations.push(location);
    }

    const searchActive = (searchTerm !== '') ? true : false;
    const filterActive = (filterCategory !== false) ? true : false;

    dispatchUpdateSearchEmptyFlag((counter === 0) ? true : false);
    dispatchUpdateMapBounds(tmpLocations, searchActive, filterActive);

    return tmpLocations;
  }

  render () {
    const locations = this.getLocations();

    return (
      <React.Fragment>
        {locations.map((itm, key) => {
          if (itm.icon_key === '' || itm.icon_key === "undefined") return <React.Fragment></React.Fragment>;

          const markerID = `marker-${key}`;
          const imgObject = this.props.getIconObj(itm.icon_key, this.props.icons);

          return <MapLayer
            key={key}
            id={markerID}
            images={[imgObject.key, imgObject.obj]}
            layout={{
              'icon-image': imgObject.key,
              'icon-size': 1,
              'icon-allow-overlap': true,
              'icon-ignore-placement': true,
              'icon-padding': 0,
            }}
            location={itm}
          />
        })}
      </React.Fragment>
    )
  }
};

const mapState = (state) => ({
  searchText: state.Map.searchText,
  searchTyping: state.Map.searchTyping,
  category: state.Map.category,
  categories: state.Map.categories,
  level: state.Map.level,
  levels: state.Map.levels,
  searchEmptyFlag: state.Map.searchEmptyFlag,
  locations: state.Map.locations,
  icons: state.Map.icons,
});

const mapDispatch = (dispatch) => ({
  getCategoryObject: (category, categories) => getCategoryObject(category, categories),
  getLevelObject: (level, levels) => getLevelObject(level, levels),
  getIconObj: (icon, icons) => getIconObj(icon, icons),
  dispatchUpdateSearchEmptyFlag: (what) => dispatch(dispatchUpdateSearchEmptyFlag(what)),
  dispatchUpdateMapBounds: (locations, searching, filtering) => dispatch(dispatchUpdateMapBounds(locations, searching, filtering)),
});

export default connect(mapState, mapDispatch)(Locations);
