import React from 'react';
import { Layer, Feature } from 'react-mapbox-gl';
import { connect } from 'react-redux';
import MapPopup from './MapPopup';
import { dispatchUpdatePopup } from '../../actions/Map.js';

class MapLayer extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      id: props.id,
      images: props.images,
      layout: props.layout,
      location: props.location,
      popup: false,
    }
  }

  componentDidUpdate(prevProps) {
    const { id, popup } = this.state;
    const { pid } = this.props;

    if (pid !== id && popup) {
      this.setState({ popup: false});
    }
  }

  renderPopup = marker => {
    const { dispatchUpdatePopup } = this.props;
    dispatchUpdatePopup(this.state.id);
    this.setState({ popup: !this.state.popup });
  }

  shouldShowLocation = location => {
    const { popup } = this.state;
    const show = ('display' in location && !location.display) ? false : true;
    if (!show && popup) this.setState({popup: false});
    return show;
  }

  render() {
    const { id, images, layout, location, popup } = this.state;

    const showLocation = this.shouldShowLocation(location);

    return (
      <React.Fragment>
        {showLocation && popup && <MapPopup location={location} renderPopup={this.renderPopup} />}
        <Layer
          type="symbol"
          id={id}
          images={images}
          layout={layout}
        >
          {showLocation && <Feature
            coordinates={[location.longitude, location.latitude]}
            onClick={() => this.renderPopup(this)}
          />}
        </Layer>
      </React.Fragment>
    )
  }
};

const mapState = (state) => ({
  pid: state.Map.pid,
});

const mapDispatch = (dispatch) => ({
  dispatchUpdatePopup: pid => dispatch(dispatchUpdatePopup(pid)),
});

export default connect(mapState, mapDispatch)(MapLayer);
