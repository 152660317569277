import React from 'react';
import ReactMapboxGl, { ZoomControl, ScaleControl, RotationControl } from 'react-mapbox-gl';
import axios from 'axios';
import { connect } from 'react-redux';
import classNames from 'classnames';
import AngleDown from '../../assets/svg/AngleDown';
import AngleUp from '../../assets/svg/AngleUp';
import MagnifyingGlass from '../../assets/svg/MagnifyingGlass';
import LoadingSVG from '../../assets/svg/LoadingSVG';

import './index.scss';
import Logo from '../../assets/svg/Logo';
import Locations from './Locations';

import {
  dispatchUpdateSearch,
  dispatchUpdateSearchTyping,
  dispatchUpdateCategory,
  dispatchUpdateCategories,
  dispatchUpdateLevel,
  dispatchUpdateLevels,
  dispatchClearFilters,
  dispatchUpdateLocations,
  dispatchUpdateIcons,
  getIconObj,
} from '../../actions/Map.js';

class MapComponent extends React.Component {

  constructor(props) {
    super(props);

    this.Map = ReactMapboxGl({
      accessToken: 'pk.eyJ1IjoicG9ydGxhbmRqZXRwb3J0IiwiYSI6ImNrN293eDkyOTAwcDYzbG8zbHdibXZha3YifQ.qjhDuBmZiucP03Egn7C2VA'
    });

    this.state = {
      api: axios.create(),
      locationURL: 'https://portlandjetport.org/data/api/map_coordinates.json?_format=json',
      categoryURL: 'https://portlandjetport.org/data/api/map_categories.json?_format=json',
      levelURL: 'https://portlandjetport.org/data/api/map_levels.json?_format=json',
      loadingLocations: true,
      loadingCategories: true,
      loadingLevels: true,
      loadingIcons: true,
      error: null,
      lng: -70.312017,
      lat: 43.6488676,
      zoom: 17,
      mobileShowCategories: false,
    };

    this.timeout = 0;
    this.searchDelay = 2000;
    this.searchInput = React.createRef();
    this.handleSearch = this.handleSearch.bind(this);
    this.clearTypingTimeout = this.clearTypingTimeout.bind(this);
    this.fetchIcons = this.fetchIcons.bind(this);
    this.finishFetchLocations = this.finishFetchLocations.bind(this);
    this.finishFetchCategories = this.finishFetchCategories.bind(this);
    this.finishFetchLevels = this.finishFetchLevels.bind(this);
    this.finishFetchIcons = this.finishFetchIcons.bind(this);
    this.mapRef = React.createRef();
  }

  shouldComponentUpdate = (nextProps, nextState) => !nextProps.searchTyping;

  componentDidMount = () => {
    this.fetchLocations();
    this.fetchCategories();
    this.fetchLevels();
  }

  componentDidUpdate = () => {
    if (this.mapRef.current !== null && typeof this.mapRef.current.state.map !== "undefined") {
      const map = this.mapRef.current.state.map;
      if (this.props.bounds[0][0] === 0) map.fitBounds(this.props.defaultBounds);
      else map.fitBounds(this.props.bounds);
    }
  }

  fetchLocations = () => {
    const { api, locationURL } = this.state;
    const { dispatchUpdateLocations } = this.props;

    const requestConfig = {};

    api.get(locationURL, requestConfig)
      .then(res => {
        let locations = [];
        for (let i in res.data) {
          let location = res.data[i];
          location.display = true;
          locations.push(location);
        }

        dispatchUpdateLocations(locations);
        this.finishFetchLocations();
        this.fetchIcons();
      })
      .catch(error => {
        if (error.response) this.setState({ error: `Error, ${error.response.status} - ${error.message}` });
      });
  }

  fetchCategories = () => {
    const { api, categoryURL } = this.state;
    const { dispatchUpdateCategories } = this.props;

    const requestConfig = {};

    api.get(categoryURL, requestConfig)
      .then(res => {
        dispatchUpdateCategories(res.data);
        this.finishFetchCategories();
      })
      .catch(error => {
        if (error.response) this.setState({ error: `Error, ${error.response.status} - ${error.message}` });
      });
  }

  fetchLevels = () => {
    const { api, levelURL } = this.state;
    const { dispatchUpdateLevels } = this.props;

    const requestConfig = {};

    api.get(levelURL, requestConfig)
      .then(res => {
        dispatchUpdateLevels(res.data);
        this.finishFetchLevels();
      })
      .catch(error => {
        if (error.response) this.setState({ error: `Error, ${error.response.status} - ${error.message}` });
      });
  }

  fetchIcons = () => {
    const { locations, dispatchUpdateIcons, getIconObj } = this.props;

    let mapIcons = [];

    for (let i = 0; i < locations.length; i++) {
      const itm = locations[i];

      if (itm.icon_key !== '' && itm.icon_key !== "undefined") {

        let imgObject = getIconObj(itm.icon_key, mapIcons);

        if (!imgObject) {
          const imgKey = `entry-image-${i}`;
          const imgUrl = `/images/icons/multi/${itm.icon_key}.png`;

          const iconImage = new Image(30, 30);
          iconImage.src = imgUrl;
          iconImage.alt = itm.icon_title;

          mapIcons.push({
            key: imgKey,
            url: imgUrl,
            obj: iconImage,
          });
        }
      }
    }

    dispatchUpdateIcons(mapIcons);
    this.finishFetchIcons();
  }

  finishFetchLocations = () => this.setState({ loadingLocations: false });
  finishFetchCategories = () => this.setState({ loadingCategories: false });
  finishFetchLevels = () => this.setState({ loadingLevels: false });
  finishFetchIcons = () => {
    const { icons } = this.props;

    for (let i in icons) {
      if (!icons[i].obj.complete) {
        setTimeout(() => this.finishFetchIcons(), 500);
        return false;
      }
    }

    this.setState({ loadingIcons: false });
  }

  handleSearch = () => {
    const { searchTyping, dispatchUpdateSearchTyping } = this.props;
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => { this.clearTypingTimeout(); }, this.searchDelay);
    if (!searchTyping) dispatchUpdateSearchTyping(true);
  }

  clearTypingTimeout = () => {
    this.props.dispatchUpdateSearch(this.searchInput.current.value);
    this.props.dispatchUpdateSearchTyping(false);
  }

  updateCategory = cat => this.props.dispatchUpdateCategory((cat.key === this.props.category) ? '' : cat.key);
  updateLevel = lvl => this.props.dispatchUpdateLevel((lvl.title === this.props.level) ? '' : lvl.title);
  toggleMobileCategory = () => this.setState({ mobileShowCategories: !this.state.mobileShowCategories });
  goHome = () => window.location = "https://portlandjetport.org";
  createMarkup = html => { return { __html: html }; };

  render() {
    const { loadingIcons, loadingLocations, loadingCategories, loadingLevels, lat, lng, zoom, mobileShowCategories } = this.state;
    const { bounds, category, categories, level, levels, dispatchClearFilters, searchEmptyFlag, searchText } = this.props;

    if (loadingLocations || loadingCategories || loadingLevels || loadingIcons) return <div className={'loading-div'}><LoadingSVG /></div>;

    const MapObject = this.Map;

    return (
      <div className="appContainer">
        <div className="controls-container">
          <div className="logoContainer" onClick={() => this.goHome()}>
            <Logo />
          </div>
          <div className="legendContainer">
            <div className="mobile-filter-toggle" onClick={() => this.toggleMobileCategory()}>
              <span>Filter</span>
              {!mobileShowCategories && <AngleDown />}
              {mobileShowCategories && <AngleUp />}
            </div>
            <div className={classNames("search-input", { 'active': mobileShowCategories })}>
              <MagnifyingGlass />
              <input
                type="text"
                onChange={this.handleSearch}
                ref={this.searchInput}
              />
            </div>

            <hr className={classNames({ 'active': mobileShowCategories })} />

            <div className={classNames("level-container", { 'active': mobileShowCategories })}>
              {levels.map((itm, key) => {
                const selected = (level === '') ? '' : (level === itm.title) ? ' selected' : ' not-selected';

                return (
                  <div key={key} className={`level-itm${selected}`} onClick={() => this.updateLevel(itm)}>
                    <div className="level-title">
                      <p>{itm.title}</p>
                    </div>
                    <div className="level-description">
                      <p dangerouslySetInnerHTML={this.createMarkup(itm.description)} />
                    </div>
                  </div>
                );
              })}
            </div>

            <hr className={classNames({ 'active': mobileShowCategories })} />

            <div className={classNames("categoryContainer", { 'active': mobileShowCategories })}>
              {categories.map((itm, key) => {
                const catImg = `/images/categories/${itm.key}.png`;
                const selected = (category === '') ? '' : (category === itm.key) ? ' selected' : ' not-selected';

                return (
                  <div key={key} className={`category-itm${selected}`} onClick={() => this.updateCategory(itm)}>
                    <div className="category-icon">
                      <img src={catImg} alt={itm.title} title={itm.title} />
                    </div>
                    <div className="category-title">
                      <p>{itm.title}</p>
                    </div>
                  </div>
                );
              })}
            </div>
            {searchEmptyFlag && <p className={'no-results-flag'}>Your search returned no results</p>}
            {(category || level || searchText !== '') && <p className={classNames('filter-active-flag', { 'active': mobileShowCategories })}><span className="button-type" onClick={() => dispatchClearFilters()}>Clear Filter</span></p>}
          </div>
        </div>
        <div className={classNames("map-object-container", { 'active': mobileShowCategories })}>
          <MapObject
            style={"mapbox://styles/portlandjetport/ckbwmbnuc0m921ipg49xdoijx"}
            center={[lng, lat]}
            fitBounds={[...bounds]}
            zoom={[zoom]}
            data={this.checkMapData}
            ref={this.mapRef}
          >
            <ZoomControl />
            <ScaleControl />
            <RotationControl />
            <Locations />
          </MapObject>
        </div>
      </div>
    )
  }
}

const mapState = state => ({
  searchText: state.Map.searchText,
  searchTyping: state.Map.searchTyping,
  searchEmptyFlag : state.Map.searchEmptyFlag ,
  category: state.Map.category,
  categories: state.Map.categories,
  level: state.Map.level,
  levels: state.Map.levels,
  bounds: state.Map.bounds,
  defaultBounds: state.Map.defaultBounds,
  icons: state.Map.icons,
  locations: state.Map.locations,
});

const mapDispatch = dispatch => ({
  getIconObj: (icon, icons) => getIconObj(icon, icons),
  dispatchUpdateSearch: search => dispatch(dispatchUpdateSearch(search)),
  dispatchUpdateSearchTyping: what => dispatch(dispatchUpdateSearchTyping(what)),
  dispatchUpdateCategory: category => dispatch(dispatchUpdateCategory(category)),
  dispatchUpdateCategories: categories => dispatch(dispatchUpdateCategories(categories)),
  dispatchUpdateLevel: level => dispatch(dispatchUpdateLevel(level)),
  dispatchUpdateLevels: levels => dispatch(dispatchUpdateLevels(levels)),
  dispatchClearFilters: () => dispatch(dispatchClearFilters()),
  dispatchUpdateLocations: locations => dispatch(dispatchUpdateLocations(locations)),
  dispatchUpdateIcons: icons => dispatch(dispatchUpdateIcons(icons)),
});

export default connect(mapState, mapDispatch)(MapComponent);
