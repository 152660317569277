export const UPDATE_POPUP = 'UPDATE_POPUP';
export const UPDATE_SEARCH = 'UPDATE_SEARCH';
export const UPDATE_SEARCH_TYPING = 'UPDATE_SEARCH_TYPING';
export const UPDATE_SEARCH_EMPTY_FLAG = 'UPDATE_SEARCH_EMPTY_FLAG';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const UPDATE_CATEGORIES = 'UPDATE_CATEGORIES';
export const UPDATE_LEVEL = 'UPDATE_LEVEL';
export const UPDATE_LEVELS = 'UPDATE_LEVELS';
export const UPDATE_MAP_BOUNDS = 'UPDATE_MAP_BOUNDS';
export const UPDATE_LOCATIONS_LOADED = 'UPDATE_LOCATIONS_LOADED';
export const UPDATE_LOCATIONS = 'UPDATE_LOCATIONS';
export const UPDATE_ICONS = 'UPDATE_ICONS';

// *************************************************
// Thunk functions
export const updateSearch = searchText => ({
  type: UPDATE_SEARCH,
  payload: { searchText },
});

export const updateSearchTyping = what => ({
  type: UPDATE_SEARCH_TYPING,
  payload: { what },
});

export const updateSearchEmptyFlag = what => ({
  type: UPDATE_SEARCH_EMPTY_FLAG,
  payload: { what },
});

export const updatePopup = pid => ({
  type: UPDATE_POPUP,
  payload: { pid },
});

export const updateCategory = category => ({
  type: UPDATE_CATEGORY,
  payload: { category },
});

export const updateCategories = categories => ({
  type: UPDATE_CATEGORIES,
  payload: { categories },
});

export const updateLevel = level => ({
  type: UPDATE_LEVEL,
  payload: { level },
});

export const updateLevels = levels => ({
  type: UPDATE_LEVELS,
  payload: { levels },
});

export const updateMapBounds = bounds => ({
  type: UPDATE_MAP_BOUNDS,
  payload: { bounds },
});

export const updateLocationsLoaded = () => ({
  type: UPDATE_LOCATIONS_LOADED,
});

export const updateLocations = locations => ({
  type: UPDATE_LOCATIONS,
  payload: { locations },
});

export const updateIcons = icons => ({
  type: UPDATE_ICONS,
  payload: { icons },
});

// *************************************************
// Redux Dispatches
export function dispatchUpdateSearch(searchText) {
  return dispatch => {
    dispatch(updateSearch(searchText));
  }
}

export function dispatchUpdateSearchTyping(what) {
  return dispatch => {
    dispatch(updateSearchTyping(what));
  }
}

export function dispatchUpdateSearchEmptyFlag(what) {
  return dispatch => {
    dispatch(updateSearchEmptyFlag(what));
  }
}

export function dispatchUpdatePopup(pid) {
  return dispatch => {
    dispatch(updatePopup(pid));
  }
}

export function dispatchUpdateCategory(category) {
  return dispatch => {
    dispatch(updateCategory(category));
  }
}

export function dispatchUpdateCategories(categories) {
  return dispatch => {
    dispatch(updateCategories(categories));
  }
}

export function dispatchUpdateLevel(level) {
  return dispatch => {
    dispatch(updateLevel(level));
  }
}

export function dispatchUpdateLevels(levels) {
  return dispatch => {
    dispatch(updateLevels(levels));
  }
}

export function dispatchClearFilters() {
  return dispatch => {
    dispatch(updateSearch(''));
    dispatch(updateCategory(''));
    dispatch(updateLevel(''));
  }
}

export function dispatchUpdateLocations(locations) {
  return dispatch => {
    dispatch(updateLocations(locations));
  }
}

export function dispatchUpdateIcons(icons) {
  return dispatch => {
    dispatch(updateIcons(icons));
  }
}

export function dispatchUpdateMapBounds(locations, searching, filtering) {
  return dispatch => {
    let north = 0;
    let south = 0;
    let east = 0;
    let west = 0;

    for (let i in locations) {
      const loc = locations[i];

      if (loc.display && (loc.ignore_bounds_off_filter !== "on" || (loc.ignore_bounds_off_filter === "on" && (searching || filtering)))) {
        const latitude = loc.latitude * 1;
        const longitude = loc.longitude * 1;

        if (north === 0) {
          north = latitude;
          south = latitude;
          west = longitude;
          east = longitude;
        }

        else {
          if (latitude > north) north = latitude;
          if (latitude < south) south = latitude;
          if (longitude < west) west = longitude;
          if (longitude > east) east = longitude;
        }
      }
    }

    if (north !== 0) {
      north -= 0.002;
      west -= 0.002;
      east += 0.002;
      south += 0.002;
    }

    dispatch(updateMapBounds([[west, north], [east, south]]));
  }
}

// *************************************************
// Misc Functions
export function getCategoryObject(category, categories) {
  for (let i = 0; i < categories.length; i++) {
    if (categories[i].key !== '' && categories[i].key === category) return categories[i];
  }
  return false;
}

export function getLevelObject(level, levels) {
  for (let i = 0; i < levels.length; i++) {
    if (levels[i].title !== '' && levels[i].title === level) return levels[i];
  }
  return false;
}

export function getIconObj(icon, icons) {
  for (let i = 0; i < icons.length; i++) {
    if (icons[i].url === `/images/icons/multi/${icon}.png`) return icons[i];
  }
  return false;
}
